import React, { Component, useContext, useState  } from "react";
import { Outlet } from 'react-router-dom';
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import CardTemplate from '../cards/CardTemplate.js';
import AddNewCard from '../cards/AddNewCard.js';
import DeleteCategoryModal from '../modals/categories/DeleteCategoryModal.js';
import EditModal from '../modals/categories/EditModal.js';
import CreateImageCategory from "../modals/categories/CreateImageCategory";

import trash from "../../../assets/media/icons/Trash.svg";
import setting from "../../../assets/media/icons/Setting.svg";
import edit from "../../../assets/media/icons/Edit.svg";
import addSmall from "../../../assets/media/icons/Add Small.svg";

import {  MDBIcon,
          MDBRow,
          MDBCol,
          MDBBtn
} from 'mdb-react-ui-kit';



var params = window.location.pathname.split("/");
const urlName = params[params.length - 1] !== "" && params[params.length - 1] !== "admin" ?
    params[params.length - 1].charAt(0).toUpperCase() + params[params.length - 1].slice(1) : "Products";

class Products extends Component {

  constructor(props) {
    super(props);
    this.state = {showModal: false,
                  status: "",
                  categorisLis: [],
                  categoryName:""
									};
  };

  componentDidMount() {
    this.onGetAllCategory();
  }

  onGetAllCategory = () => {
    let self = this;
    this.props.context.interAxios.get('/category', function(response) {
      self.setState({categorisLis: response.data});
    })
  }

  handleSaveBackground = (categoryId) => {
    //event.preventDefault();
    if(this.state.blob !== undefined){
      let self = this;
      const formData = new FormData
      formData.append("file", this.state.blob);
      this.props.context.interAxios.post('/image', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
        self.handleImageConnect(categoryId, response.data.id);
      });
    }else{
      this.onGetAllCategory();
    }

  }

  handleImageConnect = (categoryId, imageId) => {
    let self = this;
    this.props.context.interAxios.put('/category/' + categoryId + '/connect-image/' + imageId, function(response) {
      self.onGetAllCategory();
    })
  }


  onNewCategory = (title, description) => {
    let self = this;
    console.log("belépek");
    this.props.context.interAxios.post('/category', {title: title, description: description},  function(response) {
      self.handleSaveBackground(response.data.id);
    })
  }

  onUpdateCategory = (title, description, id) => {
    let self = this;
    this.props.context.interAxios.put('/category/' + id, {title: title, description: description},  function(response) {
      self.handleSaveBackground(id);
    })
  }

  onDeleteCategory = ( id) => {
    let self = this;
    this.props.context.interAxios.delete('/category/' + id,  function(response) {
      self.onGetAllCategory();
    })
  }


  onShowItem = (id, categoryName) => {
    const searchParams = new URLSearchParams();
    searchParams.append("categoryId", id);
    searchParams.append("categoryName", categoryName);
    searchParams.append("urlName", urlName);
    this.props.navigate("/admin/productsitem?" + searchParams.toString());
  };

  showEditCategory = (id, categoryName, url, description) => {
    this.setState({showModal: true,
                   categoryId: id,
                   categoryUrl: url,
                   status: id !== "" ? "Category settings" : "Add new category"})
     if(categoryName !== "") {
       this.setState({categoryName: categoryName})
     }
     if(description !== "") {
       this.setState({description: description})
     }
  };

  showDeleteCategory = (id, categoryName) => {
    let deleteText = <span className="deletemodal-text">By deleting the selected category "
                      <b>{categoryName}</b>
                      ", the included items will be also removed. <br/>Would you like to move all of the included items in the deleted category to another category?</span>;

    this.setState({showModal: true, categoryId: id, status: "Delete category", categoryName: categoryName, text: deleteText})
  };

  showNewCategory = () => {
    this.setState({showModal: true, categoryName: "", categoryUrl: "", description: "",  status: "Add new category"})
  }

  hideModal = () => {
    this.setState({showModal: false, categoryId: ""})
  }

  hideProductsItem = () => {
    this.setState({showModal: false, categoryId: "", categoryName: ""})
  }

  handleShowBackground = (id, title, description) => {
    this.hideModal();
    this.setState({showBackground: true, categoryId: id, categoryName:title, description:description
                });
  };

  handleCloseBackground = (image, id) => {
    this.setState({showBackground: false});

  };

  onCropComplete = (image, id) => {
    this.setState({url: image.url, blob: image.blob});
  /*  for(let i = 0; i < this.state.categorisLis.length; i++){
      if(this.state.categorisLis[i].id == id){
          this.state.categorisLis[i].image = { url: image.url };
        }
    }*/
    this.showEditCategory(id, "", image.url, "")
  };

  render() {
      let dropDownLis = [
        { id: "1",
          url: edit,
          text: "Show item(s)",
          function: this.onShowItem},
        { id: "2",
          url: setting,
          text: "Edit category",
          function: this.showEditCategory},
        { id: "3",
          url: trash,
          text: "Delete",
          function: this.showDeleteCategory}
      ];

      let productsCategoris = [];
      productsCategoris = this.state.categorisLis.map(
        (k, n) => {
              return (
                <MDBCol lg="4" sm="6" key={k.id} >
                  <CardTemplate id={k.id}
                                url={k.image?.url}
                                title={k.title}
                                description={k.description}
                                dropDownLis={dropDownLis}/>
                </MDBCol>
              );
            }
      )
      return (
        <div style={{width: "100%"}}>
          <MDBRow className="pt-5 me-2 ms-2">
            <MDBCol center md="6">
            <h2 className="title-text">Products categories</h2>
            <span className="url-text">{"Products"}</span>
            </MDBCol>
            <MDBCol center md="6" className="col-btn">
                <MDBBtn type="btn" onClick={this.showNewCategory} className="danfoss-btn admin">
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={addSmall}/><span> Add new category</span>
                  </div>
                </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow className="me-2 ms-2">
            {productsCategoris}
            <MDBCol md="4" className="add-col">
              <MDBBtn type="btn" onClick={this.showNewCategory}>
                <AddNewCard text={"Add new category"}/>
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBRow>
          </MDBRow>
          {this.state.showModal && this.state.status === "Delete category" ?
          <DeleteCategoryModal hideModal={this.hideModal}
                       showModal={this.state.showModal}
                       modalTyp={this.state.status}
                       categoryId={this.state.categoryId}
                       categoryName={this.state.categoryName}
                       textend={this.state.text}
                       categorisLis={this.state.categorisLis}/> : false}
          {this.state.showModal && (this.state.status === "Category settings" || this.state.status === "Add new category") ?
          <EditModal hideModal={this.hideModal}
                     onNewCategory ={this.onNewCategory}
                     onUpdateCategory={this.onUpdateCategory}
                     showModal={this.state.showModal}
                     modalTyp={this.state.status}
                     categoryId={this.state.categoryId}
                     categoryUrl={this.state.categoryUrl}
                     categoryName={this.state.categoryName}
                     description={this.state.description}
                     handleShowBackground={this.handleShowBackground}/> : false}
         {this.state.showBackground ?
       		<CreateImageCategory
               			showModal= {this.state.showBackground}
                    categoryId={this.state.categoryId}
                    onCropComplete = {this.onCropComplete}
               			hideModal = {this.handleCloseBackground}
               			/>: false}
        </div>
      )
  }
}

  export default function ProductsFunc(props) {
      const context = useContext(AppContext);
      const navigate = useNavigate();
      let location = useLocation();
      return <Products  { ...props } context={context} navigate={navigate}/>;
  }
