import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import  ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Moment from 'moment';
import 'moment/locale/de';

import DragAndDrop from '../components/DragAndDrop.js';
import AddNewCard from '../cards/AddNewCard.js';
import CreateImageHome from "../modals/categories/CreateImageHome";

import addSmall from "../../../assets/media/icons/Add Small.svg";
import arrowLeft from "../../../assets/media/icons/arrow-circle-left.png";
import edit from "../../../assets/media/icons/Edit.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";
import addNew from "../../../assets/media/icons/Add New.svg";

import { MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBCard,
         MDBCardBody,
         MDBCardTitle,
         MDBCardText,
         MDBInput,
         MDBTextArea,
         MDBFile
       } from 'mdb-react-ui-kit';
class HomeSection extends Component {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {isDeleteFunction: false,
                  location: this.props.location[0] !== undefined &&  this.props.location[0].location !== null ? this.props.location[0].location : "",
                  description:this.props.location[0] !== undefined &&  this.props.location[0].description !== null ? this.props.location[0].description : "",
                  image: this.props.location[0] !== undefined &&  this.props.location[0].image !== null ? this.props.location[0].image.url : undefined,
                  title: this.props.location[0] !== undefined &&  this.props.location[0].title !== null ? this.props.location[0].title : "",
                  linkText: this.props.location[0] !== undefined &&  this.props.location[0].linkText !== null ? this.props.location[0].linkText : "",
                  url: this.props.location[0] !== undefined &&  this.props.location[0].url !== null ? this.props.location[0].url : ""
                };

  }
  componentDidMount(){
    this.props.onArticleChange(this.state.location, this.state.description);
    this.props.onTitleChange(this.state.location, this.state.title);
    this.props.onLinkTitleChange(this.state.location, this.state.linkText);
    this.props.onUrlChange(this.state.location, this.state.url)
  }

  onDeleteFunction = () => {
    this.setState({isDeleteFunction: !this.state.isDeleteFunction})
  }

  onDeleteChange = () => {
    this.setState({selectedFile: undefined, image: undefined, isDeleteFunction: !this.state.isDeleteFunction});
    this.props.onSelectedFile(this.state.location, undefined);
  };

  onArticleChange = (editorState) => {
    let text = editorState;
    if(this.props.location[0].location === "TOP" && editorState.length >= 300){
      text = editorState.slice(0, 298)
      alert("You have reached the maximum number of characters!");
      return;

    }
    if(this.props.location[0].location === "BOTTOM_FIRST" && editorState.length >= 180){
      text = editorState.slice(0, 178)
      alert("You have reached the maximum number of characters!");
      return;

    }
    if(this.props.location[0].location === "BOTTOM_SECOND" && editorState.length >= 250){
      text = editorState.slice(0, 248)
      alert("You have reached the maximum number of characters!");
      return;

    }
    this.setState({description: text});
    this.props.onArticleChange(this.state.location, text)
  }

  onTitleChange = (event) => {
    this.setState({title: event.target.value});
    this.props.onTitleChange(this.state.location, event.target.value)
	}

  onLinkTitleChange = (event) => {
    this.setState({linkText: event.target.value});
    this.props.onLinkTitleChange(this.state.location, event.target.value)
	}

  onUrlChange = (event) => {
    this.setState({url: event.target.value});
    this.props.onUrlChange(this.state.location, event.target.value)
	}

  handleChoose = (event) => {
    this.inputRef.current.click();
  };

  /*onFileChange = (e) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    let selectedFile = {fotoUrl: url, value: file}
    this.setState({selectedFile: selectedFile, image: url});
    this.props.onSelectedFile(this.state.location, selectedFile);
  };*/

  onCropComplete = (image) => {
    console.log(image)
    this.setState({url: image.url, blob: image.blob});
    let selectedFile = {fotoUrl: image.url, value: image.blob}
    this.setState({selectedFile: selectedFile, image: image.url, blob: image.blob});
    this.props.onSelectedFile(this.state.location, selectedFile);
  };

  handleShowAddImage = () => {
      this.setState({showAddImage: true,
                     imageWidth: this.props.location[0].location === "MIDDLE_LEFT" ? 625 :
                                 this.props.location[0].location === "MIDDLE_RIGHT" ? 625 :
                                 this.props.location[0].location === "TOP" ? 750 :
                                 this.props.location[0].location === "BOTTOM_FIRST" ? 1280 :
                                 this.props.location[0].location === "BOTTOM_SECOND" ? 1280 : 625,
                     imageHeight: 420
                   });
  };

  handleCloseAddImage = (image, id) => {
    this.setState({showAddImage: false});
  };

  render(){
    return (<>
      {this.props.location[0].location !== "MIDDLE_LEFT" && this.props.location[0].location !== "MIDDLE_RIGHT" ?
      <MDBRow className='py-3'>
        <span className="add-title-text">Title</span>
        <div style={{maxWidth: "553px"}}>
        <MDBInput
                      wrapperClass='mb-4'
                      type='text'
                      id='title'
                      value={this.state.title}
                      onChange={this.onTitleChange}/>
        </div>
      </MDBRow>:false}
      <div className="section-part">

        <span className="add-title-text">Product image</span>
          <MDBBtn type="btn" className="deletefunction-btn" onClick={this.onDeleteFunction}><img src={edit}/></MDBBtn>
        <span className="add-normal-text">(Delete function)</span>
        <div className="drag-area add-col align-items-center justify-content-start">
          <MDBRow className="m-0">
            {this.state.image !== undefined ?
            <div className="d-flex align-items-center justify-content-center padding-margin-setup">
              <div className="photo-area">
                <img src={this.state.image} style={{opacity: this.state.isDeleteFunction ? "0.6" : "1"}}/>
              </div>
              {this.state.isDeleteFunction ? <>
              <div className="delete-btn d-flex align-items-center justify-content-center">
                <MDBBtn onClick={() => this.onDeleteChange()}><img src={trash}/></MDBBtn>
              </div></>:false}
            </div>:false}
            {this.state.image === undefined ?
            <div className="md-form" className="p-0" style={{width: "161px"}}>

              <MDBBtn type="btn" onClick={this.handleShowAddImage}>
                <AddNewCard text={"Add new image"} />
              </MDBBtn>
            </div>:false}
          </MDBRow>
        </div>
        {this.props.location[0].location !== "MIDDLE_LEFT" && this.props.location[0].location !== "MIDDLE_RIGHT" ?
        <MDBRow tag="form" className='py-3'>
          <span className="add-title-text">Product description <span className="add-normal-text">
              {this.props.location[0].location === "TOP" ? "(300 character)" :
               this.props.location[0].location === "BOTTOM_FIRST" ? "(180 character)" : "(250 character)"}

          </span></span>
          <div style={{maxWidth: "553px"}}>
          <CKEditor
            editor={ ClassicEditor }
            data={this.state.description}
            config={{
              toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'],
            }}

            onChange={
              ( event, editor ) => {
                const data = editor.getData();
                this.onArticleChange(data);
              }
            }
          />
          </div>
        </MDBRow>:false}
      </div>
      <MDBRow className='py-3'>
          <span className="add-title-text">Link</span>
          <span className="section-submneu-text">Link name</span>
        <div style={{maxWidth: "553px"}}>
        <MDBInput
                      wrapperClass='mb-4'
                      type='text'
                      id='linkText'
                      value={this.state.linkText}
                      onChange={this.onLinkTitleChange}/>
        </div>
          <span className="section-submneu-text">Link URL</span>
        <div style={{maxWidth: "553px"}}>
        <MDBInput
                      wrapperClass='mb-4'
                      type='text'
                      id='url'
                      value={this.state.url}
                      onChange={this.onUrlChange}/>
        </div>
      </MDBRow>
      {this.state.showAddImage ?
       <CreateImageHome
                 showModal= {this.state.showAddImage}
                 onCropComplete = {this.onCropComplete}
                 hideModal = {this.handleCloseAddImage}
                 imageWidth = {this.state.imageWidth}
                 imageHeight = {this.state.imageHeight}
                 />: false}
</>
    );
  }
}
export default function HomeSectionFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = React.useRef(null)
    return <HomeSection context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef} location={props.location} {...props}/>;
}
