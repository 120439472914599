import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'moment/locale/fr';
import DanfossAxios from './Service/DanfossAxios';
import AppContextProvider from './AppContextProvider';
import 'react-device-emulator/lib/styles/style.css';

import LandingPage from './pages/LandingPage';
import OtherPage from './pages/OtherPage';
import HomePage from './pages/HomePage';
import CategoryPage from './pages/CategoryPage';
import CategoryListPage from './pages/CategoryListPage';
import OtherPageListPage from './pages/OtherPageListPage';
import SearchPage from './pages/SearchPage';
import ProductPage from './pages/ProductPage';


import AdminLoginPage from './pages/admin/AdminLoginPage';
import AdminLandingPage from './pages/admin/AdminLandingPage';
import Products from './pages/admin/pages/Products';
import ProductsItem from './pages/admin/pages/ProductsItem';
import ProductsDetail from './pages/admin/pages/ProductsDetail';

import OtherPages from './pages/admin/pages/OtherPages';
import OtherPagesHomePage from './pages/admin/pages/OtherPagesHomePage';
import HomePageComponent from './pages/admin/pages/HomePageComponent';
import OtherPagesDetail from './pages/admin/pages/OtherPagesDetail';
import FooterComponent from './pages/admin/pages/FooterComponent';




import PrivacyPolicy from './components/LegalText/PrivacyPolicy';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.interAxios = new DanfossAxios(this);
    this.state = {pet: undefined};
  }

  componentDidMount() {
    let self = this;
    /*this.interAxios.get('/api/v3/pet/1', function(response) {
      self.setState({pet: response.data});
    })*/
  }

  render() {
    return (
			<AppContextProvider>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<LandingPage/>}>
              <Route path="" element={<HomePage/>}/>
              <Route path="home/*" element={<HomePage/>}/>
              <Route path="page/*" element={<OtherPage/>}/>
              <Route path="categorylistpage/*" element={<CategoryListPage/>}/>
              <Route path="otherpagelistpage/*" element={<OtherPageListPage/>}/>
              <Route path="categorypage/*" element={<CategoryPage/>}/>
              <Route path="searchpage/*" element={<SearchPage/>}/>
              <Route path="productpage/*" element={<ProductPage/>}/>
            </Route>
						<Route path="/admin" element={<AdminLandingPage/>}>
							<Route index element={<Products/>}/>
							<Route path="products" element={<Products/>}/>
              <Route path="productsitem" element={<ProductsItem/>}/>
              <Route path="productsdetail" element={<ProductsDetail/>}/>
              <Route path="otherpages" element={<OtherPages/>}/>
              <Route path="otherpagesdetail" element={<OtherPagesDetail/>}/>
              <Route path="otherpageshomepage" element={<HomePageComponent/>}/>
              <Route path="footercomponent" element={<FooterComponent/>}/>
							{/*<Route path="stations" element={<LandingPage/>}/>*/}
						</Route>
						<Route path="admin/login" element={<AdminLoginPage/>}/>
					</Routes>
				</BrowserRouter>
			</AppContextProvider>
    );
  }
}

export default App;
