import React from "react";
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const { REACT_APP_BACKEND_URL } = process.env;

// Paraméterek:
// process.env.REACT_APP_BACKEND_URL helyett backendUrl() from '../env'

/* Használat:
 * import InterB2BAxios from '../Service/InterB2BAxios';
 * nem kell: import axios from 'axios';
 * nem kell: cookies használata, token és myUserId átrakása state-be (hacsak más miatt nem szükséges)
 *
 * A komponens konstruktorában:
 *
 * this.interAxios = new InterB2BAxios(this);
 *
 * Ahol axios GET-hívás szükséges, ott a következőt kell kiadni:
 *
 * this.interAxios.get('/api/url/tobbi/resze', function(response) {
 *   // amit akkor kell csinálni, ha minden oké.
 * })
 *
 * Ahol axios POST-hívás szükséges, ott a következőt kell kiadni:
 * this.interAxios.post('/api/url/tobbi/resze', data_amit_elkuldunk_pl_formData, [extraConfig], function(response) {
 *   // amit akkor kell csinálni, ha minden oké.
 * }[, function(error) {
 *   // amit akkor kell csinálni, ha nem sikerült a hívás (pl. signin) - nem kötelező
 * }]);
 *
 * extraConfig paraméterben lévő adatokat hozzáfésüli az axios configjához (token és canceltoken)
 * Az extraConfig formátuma:
 * {
 *   params: { 'a': 'b'},
 *   headers: { 'c': 'd'}
 * }
 *
 * Ha a komponens lekerül a képernyőről (componentWillUnmount), akkor abban
 * hívd meg a this.interAxios.cancel() függvényt
 */

class DanfossAxios {
  constructor(name, getToken) {
    this.name = name;
    this.cancelTokenSource = axios.CancelToken.source();
    //this.authToken = cookies?.get('authtoken')?.token;
    this.getToken = getToken;
    this.errorHandling.bind(this);
    this.cancelHandling.bind(this);
    this.get.bind(this);
    this.post.bind(this);
    this.delete.bind(this);
    this.put.bind(this);
    this.refreshUserData.bind(this);
  }

  get(urlEnding, extraConfig, thenFunction) {
    if (typeof extraConfig === "function") {
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);
    console.log('REACT_APP_BACKEND_URL', REACT_APP_BACKEND_URL);
//    console.log('>>>Starting GET ('+this.name+'): ' + urlEnding + ' with token: ' + this.shortJwt())
    let promise = axios.get(REACT_APP_BACKEND_URL+urlEnding, config)
		.then(thenFunction)
//    .then((response) => {console.log('<<<Finished GET: ' + urlEnding + ' with token: ' + this.shortJwt()); thenFunction(response);})
//    .catch((error) => {console.log('<<<Error GET: ' + urlEnding + ' with token: ' + this.shortJwt()); this.catchFunction(this, error);});
    .catch((error) => this.catchFunction(this, error));
    return promise;
  }

  post(urlEnding, data, extraConfig, thenFunction, errorFunction) {
    if (typeof extraConfig === "function") { // extraConfig is not given
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);
    //console.log('>>>Starting POST: ' + urlEnding + ' with token: ' + this.shortJwt())
    let promise = axios.post(REACT_APP_BACKEND_URL+urlEnding, data, config)
		.then(thenFunction)
//    .then((response) => {console.log('<<<Finished POST: ' + urlEnding + ' with token: ' + this.shortJwt()); thenFunction(response);})
//    .catch((error)=>{console.log('no way!');})
//    .catch((error) => {console.log('<<<Error GET: ' + urlEnding + ' with token: ' + this.shortJwt()); this.catchFunction(this, error);});
//    .catch((error) => {console.log('<<<Error POST: ' + urlEnding + ' with token: ' + this.shortJwt()); this.catchFunction(this, error);});
    .catch((error) => this.catchFunction(this, error));
    return promise;
  }


  delete(urlEnding, extraConfig, thenFunction) {
    if (typeof extraConfig === "function") {
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);

    let promise = axios.delete(REACT_APP_BACKEND_URL+urlEnding, config)
    .then(thenFunction)
    .catch((error) => this.catchFunction(this, error));
    return promise;
  }

  put(urlEnding, data, extraConfig, thenFunction, errorFunction) {
    if (typeof data === "function") { // data and extraConfig is missing
      errorFunction = extraConfig;
      thenFunction = data;
    }
    if (typeof extraConfig === "function") { // extraConfig is not given
      errorFunction = thenFunction;
      thenFunction = extraConfig;
      extraConfig = undefined;
    }

    let config = this.addDefaultConfig(extraConfig);
    let promise = axios.put(REACT_APP_BACKEND_URL+urlEnding, data, config)
		.then(thenFunction)
    .catch((error) => this.catchFunction(this, error));
    return promise;
  }

  cancel(message) {
    this.cancelTokenSource.cancel(message);
  }

  refreshUserData(userData) {
    //console.log("refreshUserData ("+this.name+")");
    if (userData !== undefined) {
      this.authToken = userData.token();
      //console.log("token=" + userData.token());
    } else {
      console.log('userData is not defined!');
    }
  }

  setToken(jwt) {
    this.authToken = jwt;
  }

  // should be private

  shortJwt() {
    if (this.authToken === undefined)
      return 'no_token';
    let parts = this.authToken.split('.');
    //console.log(parts[2]);
    return parts[2].substring(0,10);
  }

  addDefaultConfig(extraConfig) {
    let config = extraConfig ?? {};
    config.headers = {...config.headers, 'Authorization': 'Bearer ' + this.getToken()};
    config.cancelToken = this.cancelTokenSource.token;
    config.validateStatus = function(status) { return status >= 200 && status < 250 };
    return config;
  }

  catchFunction(self, error, errorFunction) {
    console.log('error')
    if (axios.isCancel(error)) {
      self.cancelHandling(error);
    } else {
      if (errorFunction === undefined) {
        self.errorHandling(error);
      } else {
        errorFunction(error);
      }
    }
  }

  errorHandling(error) {
    console.log(error + 'Problem');
  }

  cancelHandling(error) {
    console.log('Request canceled', error.message ?? '');
  }

}

export default DanfossAxios;
