import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../AppContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Moment from 'moment';
import 'moment/locale/de';
import { arrayMoveImmutable } from 'array-move';
import { SortableContainer } from 'react-sortable-hoc';

import DeleteModal from '../modals/categories/DeleteModal.js';
import DragAndDrop from '../components/DragAndDrop.js';
import VideoComponent from "../section/VideoSection";
import UrlComponent from "../section/UrlSection";
import DocumentComponent from "../section/DocumentSection";
import TextComponent from "../section/TextSection";
import SelectSectionModal from '../modals/products/SelectSectionModal.js';
import CreateImageCategory from "../modals/categories/CreateImageCategory";
import SoftwareSection from '../section/SoftwareSection';

import ViewPage from "./ViewPage";

import {
  arrayMove
} from '@dnd-kit/sortable';

import addSmall from "../../../assets/media/icons/Add Small.svg";
import arrowLeft from "../../../assets/media/icons/arrow-circle-left.png";
import edit from "../../../assets/media/icons/Edit.svg";
import trash from "../../../assets/media/icons/Trash-red.svg";
import addNew from "../../../assets/media/icons/Add New.svg";

import { MDBTable,
         MDBTableHead,
         MDBTableBody,
         MDBRow,
         MDBCol,
         MDBBtn,
         MDBCard,
         MDBCardBody,
         MDBCardTitle,
         MDBCardText,
         MDBInput,
         MDBTextArea,
         MDBSelect,
         MDBSpinner,
         MDBAlert
       } from 'mdb-react-ui-kit';
import { MDBSortable, MDBSortableElement } from 'mdb-react-drag-and-drop';
class ProductsDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {isDeleteFunction: false,
                  softwareList: [],
                  newSoftwareList: [],
                  newSoftwareListFromOutside: [],
                  softwarelistAll: [],
                  showPreview: false,
                  description:"",
                  videoUrl:"",
                  fotoUrl:"",
                  status: true,
                  available: true,
                  descriptionTitle : "",
                  weight: this.props.searchParams.get("index") !== "-1" ? parseInt(this.props.searchParams.get("index")) + 1 : "Default",
                  title: this.props.searchParams.get("productName") !== "" ? "Edit " + this.props.searchParams.get("productName") : "Add product name",
                  showAddImage: false};

  }

  componentDidMount() {
    let serialNumberList = [{text: "Default", value:-1, disabled: true, defaultSelected: this.state.weight === "Default"}];
    if(this.props.searchParams.get("productName") !== ""){
      this.onGetProductById(this.props.searchParams.get("productId"));
      for(let i=0; i<parseInt(this.props.searchParams.get("itemsCount")) ; i++){
        let number = i + 1;
        serialNumberList.push({text: number+"", value:i, defaultSelected: parseInt(this.props.searchParams.get("index")) === i});
      }
    }else{
      for(let i=0; i<parseInt(this.props.searchParams.get("itemsCount"))+1; i++){
        let number = i + 1;
        serialNumberList.push({text: number+"", value:i, defaultSelected: parseInt(this.props.searchParams.get("index")) === i});
      }
    }
    this.setState({serialNumberList: serialNumberList});
  }
  onGetProductById = (id) => {
    let self = this;
    this.props.context.interAxios.get('/product/' + id , function(response) {
      let array = response.data.sections;
      
      for(let i=0; i< array.length; i++){
        if(array[i].sectionType === "TEXT"){
          array[i].sectionType = TextComponent;
        }
        if(array[i].sectionType === "VIDEO"){
          array[i].sectionType = VideoComponent;
        }
        if(array[i].sectionType === "LINK"){
          array[i].sectionType = UrlComponent;
        }
        if(array[i].sectionType === "DOCUMENT"){
          array[i].sectionType = DocumentComponent;
        }
      }
      self.setState({title: response.data.title,
                     descriptionTitle: response.data.descriptionTitle !== null ? response.data.descriptionTitle : "",
                     description: response.data.description,
                     status: response.data.status,
                     available: response.data.available,
                     selectedFiles: response.data.images !== null ? response.data.images : [],
                     softwareList: response.data.software !== null ? response.data.software : [],
                     newSoftwareList: response.data.software !== null ? response.data.software : [],
                     showSoftwareSection: response.data.software.length > 0 ? true : false,
                     sectionArray: array,
                     product: response.data});
    })
  }

  onSaveProduct = () => {
    console.log('onSaveProduct');
    let isComplete = true;
    if(this.state.title !== "Add product name"){
      let array = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
      for(let i=0; i< array.length; i++){
        if(array[i].trashBin !== true || array[i].trashBin === undefined){
          if(array[i].sectionType === VideoComponent){
            if(array[i].name==="" || array[i].name===null || array[i].name===undefined){
              alert("Fill in the video section name field!");
              isComplete = false;
              break;
            }
          }
        }
      }
      if(isComplete){
        console.log('onSaveProduct: comp-->text');
        for(let i=0; i< array.length; i++){
            if(array[i].trashBin === true){
              array.splice(i, 1);
              i--;
            }
        }
        for(let i=0; i< array.length; i++){
          if(array[i].trashBin !== true || array[i].trashBin === undefined){
            if(array[i].sectionType === VideoComponent){
              array[i].sectionType = "VIDEO";
            }
            if(array[i].sectionType === TextComponent){
              array[i].sectionType = "TEXT";
            }
            if(array[i].sectionType === UrlComponent){
              array[i].sectionType = "LINK";
            }
            if(array[i].sectionType === DocumentComponent){
              array[i].sectionType = "DOCUMENT";
            }
          }
        }
        
        let self = this;
        if(this.props.searchParams.get("productId") !== ""){
          this.props.context.interAxios.put('/product/' + this.props.searchParams.get("productId"),
          {title: this.state.title,
           descriptionTitle : this.state.descriptionTitle,
           description: this.state.description,
           status: this.state.status,
           available: this.state.available,
           weight: this.state.weight-1,
           sections: array
          }
          , function(response) {
            self.handleSaveSoftware(response.data.id)
            .then(() => {
              console.log('handleSaveImage is about to start')
              self.handleSaveImage(response.data.id);
            });
          })
        }else{
          this.props.context.interAxios.post('/category/' + this.props.searchParams.get("categoryId") + '/product',
          {title: this.state.title,
           descriptionTitle :this.state.descriptionTitle,
           description: this.state.description,
           status: this.state.status,
           available: this.state.available,
           weight: this.state.weight-1,
           sections: array
          }
          , function(response) {
            self.handleSaveSoftware(response.data.id)
            .then(() => {
              console.log('handleSaveImage is about to start')
              self.handleSaveImage(response.data.id);
            });
          })
        }
      }
    }
  }

  onWeightChange = e => {
    this.setState({weight: e.text})
  }

  handleSaveSoftware = (id) => {
    let self = this;
    let array = [];
    array = this.state.newSoftwareList.concat(this.state.newSoftwareListFromOutside);
    return this.props.context.interAxios.put('/product/'+ id + '/connect-software-ordered',
      array, function(response) {})
  }

  handleShowAddImage = () => {
    let array = this.state.selectedFiles !== undefined ? this.state.selectedFiles.filter((k)=>(!k.trashBin)) : [];
    if(array.length < 21){
      this.setState({showAddImage: true});
    }else{
      alert("You can upload a maximum of 20 images");
    }

  };

  handleCloseAddImage = (image, id) => {
    this.setState({showAddImage: false});
  };

  onCropComplete = (image, id) => {
    this.setState({url: image.url, blob: image.blob});
    let list = this.state.selectedFiles !== undefined ? this.state.selectedFiles : [];
    list.push({url: image.url, value: image.blob});
    this.setState({selectedFiles: list});
  };

  onFileChange = (e) => {
    let list = this.state.selectedFiles !== undefined ? this.state.selectedFiles : [];
    for (let i = 0; i < e.target.files.length; i++) {
      list.push({url: URL.createObjectURL(e.target.files[i]), value: e.target.files[i],});
    }
    this.setState({selectedFiles: list});
  };

  handleSaveImage = (productId) => {
    if(this.state.selectedFiles !== undefined) {
      let prom=[];
      let array = this.state.selectedFiles
      for(let i=0; i<this.state.selectedFiles.length; i++){
        if(!this.state.selectedFiles[i].trashBin && this.state.selectedFiles[i].value !== undefined){
          let self = this;
          const formData = new FormData
          formData.append("file", this.state.selectedFiles[i].value);
          prom.push(this.props.context.interAxios.post('/image', formData, { headers: {'Content-Type': 'multipart/form-data'}}, function(response) {
            array[i].id=response.data.id;
            array[i].url=response.data.url
          }));
        }
      }
      Promise.all(prom).then(() => this.handleImageConnect(productId, array));
    }else{
      this.backProductsitem();
    }

  }

  handleImageConnect = (productId, imageIdArray) => {
    let imageIdList = imageIdArray.filter((k)=>(!k.trashBin)).map((k)=>(k.id));
    let self = this;
    this.props.context.interAxios.put('/product/' + productId + '/connect-images-ordered', imageIdList, function(response) {
      self.backProductsitem();
    })
  }

  addNameToSection = (index, name) => {
    let array = this.state.sectionArray;
    array[index].name=name;
    this.setState({sectionArray: array});
  }
  addTextToSection = (index, text) => {
    let array = this.state.sectionArray;
    array[index].text=text;
    this.setState({sectionArray: array});
  }
  addUrlToSection = (index, url) => {
    let array = this.state.sectionArray;
    array[index].url=url;
    this.setState({sectionArray: array});
  }
  addTypeToSection = (index, type) => {
    let array = this.state.sectionArray;
    array[index].type=type;
    this.setState({sectionArray: array});
  }
  addLanguageToSection = (index, language) => {
    let array = this.state.sectionArray;
    array[index].language=language;
    this.setState({sectionArray: array !== "" ? array : "N/A"});
  }
  addValidForCountriesToSection = (index, countries) => {
    let array = this.state.sectionArray;
    array[index].validForCountries = countries !== null ? countries : "";
    this.setState({sectionArray: array});
  }

  backProductsitem = () => {
    const searchParams = new URLSearchParams();
    searchParams.append("categoryId", this.props.searchParams.get("categoryId"));
    searchParams.append("categoryName", this.props.searchParams.get("categoryName"));
    this.props.navigate("/admin/productsitem?" + searchParams.toString());
  }



  addVideoSection = () => {
     this.hideModal();
     let sectionArray = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
     sectionArray.push({sectionType: VideoComponent});
     this.setState({sectionArray: sectionArray});
  }

  addUrlSection = () => {
     this.hideModal();
     let sectionArray = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
     sectionArray.push({sectionType: UrlComponent});
     this.setState({sectionArray: sectionArray});
  }

  addDocumentSection = () => {
     this.hideModal();
     let sectionArray = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
     sectionArray.push({sectionType: DocumentComponent});
     this.setState({sectionArray: sectionArray});
  }

  addTextSection = () => {
     this.hideModal();
     let sectionArray = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
     sectionArray.push({sectionType: TextComponent});
     this.setState({sectionArray: sectionArray});
  }

  addSoftwareSection = () => {
    this.hideModal();
     this.setState({showSoftwareSection: true});
  }

  deleteSoftwareSection = () => {
     this.setState({showSoftwareSection: false, newSoftwareList: []});
  }

  onSoftwarelist = (array) => {
    this.setState({newSoftwareList: array});
  }


  onSoftwarelistFromOutside = (array) => {
    this.setState({newSoftwareListFromOutside: array});
  }

  onDeleteSection = (index) => {
    let list = this.state.sectionArray;
    console.log(list)
    list[index].trashBin = true;
    this.setState({sectionArray: list});
  }

  showModal = () => {
    this.setState({showModal: true})
  };

  hideModal = () => {
    this.setState({showModal: false})
  }


  onDeleteFunction = () => {
    this.setState({isDeleteFunction: !this.state.isDeleteFunction})
  }

  onArticleChange = (editorState) => {
    this.setState({description: editorState});
  }
  onTitleChange = (event) => {
    this.setState({title: event.target.value});
	}

  onProductDescriptionTitleChange = (event) => {
    this.setState({descriptionTitle: event.target.value});
	}

  onDeleteChange = (index) => {
    let list = this.state.selectedFiles
    list[index].trashBin = true;
    this.setState({selectedFiles: list});
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(
      {selectedFiles: arrayMoveImmutable(this.state.selectedFiles, oldIndex, newIndex)}
    );
  }

  handleDragEnd = (event) => {
   const {active, over} = event;
   let array = this.state.selectedFiles.map((k) => (k.url));
   if (active.id !== over.id) {
   const oldIndex = array.indexOf(active.id);
   const newIndex = array.indexOf(over.id);
   this.setState(
     {selectedFiles: arrayMove(this.state.selectedFiles, oldIndex, newIndex)}
   );
   }
 }

  showPreview = () => {
    let product = { title: this.state.title,
                    descriptionTitle: this.state.descriptionTitle,
                    description: this.state.description,
                    sections: this.state.sectionArray !== undefined ? this.state.sectionArray : [],
                    status: true,
                    software: this.state.newSoftwareList,
                    softwarelistAll: this.state.softwarelistAll,
                    images: this.state.selectedFiles.filter(k => !k.trashBin)
                  };

      this.setState({showPreview: true, previewProduct: product})
  }

  hidePreview = () => {
    this.setState({showPreview: false});
    let array = this.state.sectionArray !== undefined ? this.state.sectionArray : [];
    for(let i=0; i< array.length; i++){
      if(array[i].sectionType === "TEXT"){
        array[i].sectionType = TextComponent;
      }
      if(array[i].sectionType === "VIDEO"){
        array[i].sectionType = VideoComponent;
      }
      if(array[i].sectionType === "LINK"){
        array[i].sectionType = UrlComponent;
      }
      if(array[i].sectionType === "DOCUMENT"){
        array[i].sectionType = DocumentComponent;
      }
    }
  }

  onGetSoftwareList = (softwarelist) => {
    let softwarelistAll = softwarelist.filter(k => this.state.newSoftwareList.includes(k.id));
    this.setState({softwarelistAll: softwarelistAll})
  }

  
 

  render(){
    let productName = this.props.searchParams.get("productName") !== "" ? "Edit " + this.props.searchParams.get("productName") : "Add product";
    let sectionList = [];
    console.log(this.state.sectionArray)
    if(this.state.sectionArray !== undefined){
      sectionList = this.state.sectionArray.map(
        (k, n) => {
          let Tag = k.sectionType;
          return <Tag key={n}
                      id={k.id}
                      index={n}
                      onDeleteSection={this.onDeleteSection}
                      name={k.name!==null ? k.name : ""}
                      text={k.text!==null ? k.text : ""}
                      url={k.url!==null ? k.url : ""}
                      type={k.type!==null ? k.type : ""}
                      language={k.language!==null ? k.language : ""}
                      validForCountries={k.validForCountries}
                      trashBin={k.trashBin !== undefined ? k.trashBin : false}
                      addNameToSection={this.addNameToSection}
                      addTextToSection={this.addTextToSection}
                      addUrlToSection={this.addUrlToSection}
                      addTypeToSection={this.addTypeToSection}
                      addLanguageToSection={this.addLanguageToSection}
                      addValidForCountriesToSection={this.addValidForCountriesToSection}
                      />;
            }
      )
    }

    return (
      <div style={{width: "100%"}} className="px-2">
        <MDBRow className="pt-5">
          <MDBCol center md="2" sm="2" className="padding-col">
            <MDBBtn type="btn" onClick={this.backProductsitem} className="transparent-btn">
              <div className="d-flex align-items-center justify-content-center">
                <img src={arrowLeft}/>
              </div>
            </MDBBtn>
          </MDBCol>
          <MDBCol center className="margin-col">
          <h2 className="title-text">{productName}</h2>
          <span className="url-text">{"Products - " + this.props.searchParams.get("categoryName") + " - " + productName}</span>
          </MDBCol>
          <MDBCol center md="auto" className="col-btn" style={{marginLeft: "73px"}}>
              <MDBBtn type="btn" onClick={this.onSaveProduct} className="newpage-btn">
                <div className="d-flex align-items-center justify-content-center">
                  <span>Save</span>
                </div>
              </MDBBtn>
          </MDBCol>
          <MDBCol center md="auto" className="col-btn">
              <MDBBtn type="btn" onClick={this.showPreview} className="newpage-btn">
                <div className="d-flex align-items-center justify-content-center">
                  <span>Preview</span>
                </div>
              </MDBBtn>
          </MDBCol>
        </MDBRow>
        {this.props.searchParams.get("productName") === "" || (this.state.product !== undefined && this.state.product.status && this.state.product.available) ?
        <MDBRow className="py-4">
        <div style={{maxWidth: "200px"}}>
          <span>Position in list <span style={{color: "red"}}>*</span></span>
          {this.state.serialNumberList !== undefined ?
          <MDBSelect
            id="dropdown"
            onValueChange={(e) => this.onWeightChange(e)}
            data={this.state.serialNumberList}
          />:false}
        </div>
        </MDBRow>: false}
      <div className="section-part">
        <MDBRow tag="form" className='py-3'>
            <span className="add-title-text">Product name</span>
            <div style={{maxWidth: "553px"}}>
            <MDBInput
                          wrapperClass='mb-2'
                          type='text'
                          id='title'
                          value={this.state.title !== undefined ? this.state.title : ""}
                          onChange={this.onTitleChange}/>
            </div>

        </MDBRow>

        <span className="add-title-text">Product image</span>
          <MDBBtn type="btn" className="deletefunction-btn" onClick={this.onDeleteFunction}><img src={edit}/></MDBBtn>
        <span className="add-normal-text">(Delete function)</span>
        <div ref={this.props.nodeRef}>
          <DragAndDrop isDeleteFunction={this.state.isDeleteFunction}
                       cardText={"Add new category"}
                       onFileChange={this.onFileChange}
                       selectedFiles={this.state.selectedFiles !== undefined ? this.state.selectedFiles : []}
                       onDeleteChange={this.onDeleteChange}
                       handleShowAddImage={this.handleShowAddImage}
                       onSortEnd={this.onSortEnd}
                       handleDragEnd={this.handleDragEnd}/>
        </div>
        <br/><span className="comment-text">Your first picture is the thumbnail! Just simply drag and drop the image you want</span>
        <MDBRow tag="form" className='pt-3'>
            <span className="add-title-text">Product description title</span>
            <div style={{maxWidth: "553px"}}>
            <MDBInput
                          wrapperClass='mb-2'
                          type='text'
                          id='descriptionTitle'
                          value={this.state.descriptionTitle !== undefined ? this.state.descriptionTitle : ""}
                          onChange={this.onProductDescriptionTitleChange}/>
            </div>

        </MDBRow>
        <MDBRow tag="form" className='pb-3'>
          <span className="add-title-text">Product description<span className="add-normal-text"> (400 character)</span></span>
          <div style={{maxWidth: "553px"}}>
          <CKEditor
            editor={ ClassicEditor }
            data={this.state.description !== undefined ? this.state.description : ""}
            stylesSet ={'custom_styles' [
                                { name: 'Paragraph', element: 'p' },
                                { name: 'Heading 3', element: 'h3' },
                                { name: 'Heading 4', element: 'h4' },
                                { name: 'Marker: Yellow', element: 'span', styles: { 'background-color': 'Yellow' } },
                                { name: 'Custom Image', element: 'img', attributes: { 'class': 'myClass' } }
                            ]}
            config={ { toolbar: ['Heading', '|', 'Bold', 'Italic', 'Link', 'BulletedList', 'NumberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'], removePlugins: [ 'MediaEmbed' ] } }
            onChange={
              ( event, editor ) => {
                const data = editor.getData();
                this.onArticleChange(data);
              }
            }
          />
          </div>
        </MDBRow>
 
        {sectionList}
        {this.state.showSoftwareSection ?
        <MDBRow>
          <SoftwareSection deleteSoftwareSection={this.deleteSoftwareSection}
                           onSoftwarelist={this.onSoftwarelist}
                           onSoftwarelistFromOutside={this.onSoftwarelistFromOutside}
                           softwareList={this.state.softwareList}
                           onGetSoftwareList={this.onGetSoftwareList}/>
        </MDBRow>:false}
      </div>
      <MDBRow className="m-0">
      <div className="addNew-section d-flex align-items-center justify-content-center">
        <MDBBtn type="btn" onClick={this.showModal}>

            <div style={{height: "60%"}} className="d-flex align-items-end justify-content-center">
              <img src={addNew}/><br/>
            </div>
            <div className="d-flex align-items-end justify-content-center pt-1">
              <p>Add new Section</p>
            </div>

        </MDBBtn>
        </div>
      </MDBRow>
      {this.state.showModal ?
      <SelectSectionModal hideModal={this.hideModal}
                          showModal={this.state.showModal}
                          addVideoSection={this.addVideoSection}
                          addSoftwareSection={this.addSoftwareSection}
                          addUrlSection={this.addUrlSection}
                          addDocumentSection={this.addDocumentSection}
                          showSoftwareSection={this.state.showSoftwareSection}
                          addTextSection={this.addTextSection}/> : false}
      {this.state.showAddImage ?
       <CreateImageCategory
                 showModal= {this.state.showAddImage}
                 onCropComplete = {this.onCropComplete}
                 hideModal = {this.handleCloseAddImage}
                 />: false}
     {this.state.showPreview ?
      <ViewPage
                showModal= {this.state.showPreview}
                product = {this.state.previewProduct}
                hideModal = {this.hidePreview}
                array={this.state.softwarelistAll}
                />: false}

      </div>


    );
  }
}
export default function ProductsDetailFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = React.useRef(null)
    return <ProductsDetail context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef}/>;
}
